export const ensureValidUrl = (url: string) => {
  if (!url) return;

  const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  try {
    // Attempt to create a URL object (validates the URL)
    const validUrl = new URL(url);

    if (domainPattern.test(validUrl.hostname)) {
      return validUrl.href; // Return valid URL
    } else {
      throw new Error(
        `Domain "${validUrl.hostname}" is not allowed or invalid`
      );
    }
  } catch (error) {
    // If it's not a valid URL, try adding "https://" and validate again
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      try {
        const correctedUrl = new URL(`https://${url}`);

        if (domainPattern.test(correctedUrl.hostname)) {
          return correctedUrl.href;
        } else {
          throw new Error(
            `Domain "${correctedUrl.hostname}" is not allowed or invalid`
          );
        }
      } catch (error) {
        return;
      }
    }
    return;
  }
};
