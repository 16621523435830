import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FullLogoWithStepper from "../../../assets/images/full_logo_white_with_stepper.svg";
import Button from "@mui/material/Button";

import { useAppDispatch } from "../../../store/hook";
import { setMyProfileProps } from "../../../store/modules/meSlice";

const dialogPaperStyle = {
  display: { lg: "flex", xs: "block" },
  flexDirection: "row",
  borderRadius: "24px",
  width: "640px",
  maxWidth: { lg: "unset", xs: "100%" },
  height: { lg: "418px", xs: "auto" },
};

export const ThanksgivingSeasonDialog = ({ open }: any) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setMyProfileProps({ showPopup: null }));
  }

  const handleSubmit = () => {
    window.open("https://webapp.getthereferral.com/?companyCode=2180", '_blank')?.focus()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <Stack
        sx={{
          flex: "auto",
          alignItems: "center",
          padding: "32px",
          textAlign: "center",
          gap: "15px",
        }}
      >
        <Box>
          <img
            src={FullLogoWithStepper}
            alt="full logo"
            loading="lazy"
            style={{ width: "100%" }}
          />
        </Box>
        <Typography
          sx={{
            padding: "0 20px",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#3D4D5C"
          }}
        >
          Don’t miss out on $1,500 this Thanksgiving season
        </Typography>
        <Typography
          sx={{
            padding: "0 20px",
            fontSize: "16px",
            lineHeight: "24px",
            marginBottom: "40px",
            textAlign: "left",
            color: "#3D4D5C"
          }}
        >
          Refer your friends during our Give Thanks, Get Thanks program this November. You and your referral receive $1,500 each when they make the switch to solar!
        </Typography>
        <Button
          LinkComponent="a"
          variant="contained"
          sx={{
            marginTop: "auto",
            borderRadius: "100px",
            width: { lg: "332px", xs: "166px" },
          }}
          onClick={handleSubmit}
        >
          Click Here to Refer
        </Button>
      </Stack>

      <IconButton
        color="inherit"
        size="small"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Dialog>
  );
};
