import { combineReducers } from "@reduxjs/toolkit";
import me from "./modules/meSlice";
import portal from "./modules/portalSlice";
import faq from "./modules/faqSlice";
import document from "./modules/documentSlice";

export const rootReducer = combineReducers({
  me,
  portal,
  faq,
  document
});
