import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

interface MeState {
  id: string | null;
  email: string | null;
  phoneNumber: string | null;
  auth: string | null;
  lastVisitedPortal: string | null;
  portals: IUserPortal[];
  pendingDownloadFiles: string[];
  isImpersonating: boolean;
  impersonatePortal: string | null;
  impersonateTtl: string | null;
  customerId: string | null;
  isPromotionDisplayed: boolean;
  showOffer: boolean;
  notificationPrefs: NotificationPrefs;
  showPopup: string | null;
  onboardingStep?: string;
  emailVerified?: boolean;
  phoneNumberVerified?: boolean;
}

interface IUserPortal {
  id: string;
  address: string;
  createdAt: string;
}
interface NotificationPrefs {
  sms: boolean;
  smsDisabled?: boolean;
}

const initialState: MeState = {
  id: null,
  email: null,
  phoneNumber: null,
  auth: null,
  lastVisitedPortal: null,
  portals: [],
  pendingDownloadFiles: [],
  isImpersonating: !!localStorage.getItem("impersonator"),
  impersonatePortal: localStorage.getItem("impersonateportal"),
  impersonateTtl: localStorage.getItem("impersonatettl"),
  customerId: null,
  isPromotionDisplayed: false,
  showOffer: false,
  showPopup: null,
  notificationPrefs: {
    sms: false
  }
};

export const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    setMyProfileProps: (state, action: PayloadAction<any>) => {
      const updateObj = action.payload;

      _.each(updateObj, (v, k) => _.set(state, k, v));
      return state;
    },
    setMyProfile: (state, action: PayloadAction<any>) => {
      return Object.assign(state, action.payload);
    },
    logout: (state) => {
      return Object.assign(state, initialState);
    },
    setPendingDownloadFile: (state, action: PayloadAction<string[]>) => {
      return Object.assign(state, { pendingDownloadFiles: action.payload });
    }
  }
});

export const {
  setMyProfileProps,
  setMyProfile,
  logout,
  setPendingDownloadFile
} = meSlice.actions;

export default meSlice.reducer;
