import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

// interface IPortal extends IPortalData {
//   orgId: string; // Sunrise org ID
//   id: string; // Sunrise project ID
//   contactId: string; // Sunrise customer ID
//   secondaryContactId?: string | null;
//   phoneNumberVerified: boolean;
//   addressConfirmed: boolean;
//   onboardingStep: string;
// }

// interface IPortalData extends IPortalContact {
//   secondaryContact?: IPortalContact;
//   propertyDetails?: IPortalPropertyDetails;
//   hoaDetails?: IPortalHoaDetails;
//   systemDetails?: IPortalSystemDetails;
// }

// interface IPortalContact {
//   firstName?: string; // Computed property from sunrise
//   lastName?: string; // Computed property from sunrise
//   phoneNumber?: string; // Computed property from sunrise
//   email?: string; // Computed property from sunrise
//   address?: string; // Computed property from sunrise
// }

// interface IPortalPropertyDetails {
//   utilityCompany: string; // Computed property from sunrise
//   gateCode: string; // Computed property from sunrise
//   wifiNetwork: string; // Computed property from sunrise
//   wifiPassword: string; // Computed property from sunrise
// }

// interface IPortalHoaDetails {
//   hoaExist: string; // Computed property from sunrise
//   name: string; // Computed property from sunrise
//   email: string; // Computed property from sunrise
//   phoneNumber: string; // Computed property from sunrise
//   portalWebsite: string; // Computed property from sunrise
//   portalUsername: string; // Computed property from sunrise
//   portalPassword: string; // Computed property from sunrise
// }

// interface IPortalSystemDetails {
//   panelType: string; // Computed property from sunrise
//   panelQuantity: string; // Computed property from sunrise
//   monitoringDevice: string; // Computed property from sunrise
//   warrantyNumber: string; // Computed property from sunrise
//   systemSize: string; // Computed property from sunrise
//   teslaPowerwallsQuantity: string; // Computed property from sunrise
//   amount: string; // Computed property from sunrise
//   lender: string; // Computed property from sunrise
//   yearlyProduction: string; // Computed property from sunrise
//   loanExpirationDate: string; // Computed property from sunrise
// }

const initialState: any = {
  orgId: null,
  id: null,
  contactId: null,
  secondaryContactId: null,
  // emailVerified: false,
  // phoneNumberVerified: false,
  // addressConfirmed: false,
  // onboardingStep: null,
  tracker: {},
  fields: {},
  banners: {},
  fspProperties: {},
  velocity: {},
  isCanceled: false,
  popup: undefined
};

export const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    setPortalProps: (state, action: PayloadAction<any>) => {
      const updateObj = action.payload;

      _.each(updateObj, (v, k) => _.set(state, k, v));
      return state;
    },
    setPortal: (state, action: PayloadAction<any>) => {
      return Object.assign(state, action.payload);
    }
  }
});

export const { setPortalProps, setPortal } = portalSlice.actions;

export default portalSlice.reducer;
