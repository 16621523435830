import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import _ from "lodash";

import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../components/shared/phoneInput";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { MiddleContainer } from "../../components/signup/middleContainer";
import { BottomCtaContainer } from "../../components/signup/bottomCtaContainer";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";
import { setMyProfile } from "../../store/modules/meSlice";

import * as api from "../../api";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

const defaultValue = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const errorDefault = {
  email: "",
  phoneNumber: "",
};

export const SecondContact = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, email, secondaryContact, secondaryContactId } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [formData, setFormData] = useState(defaultValue);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(errorDefault);
  const [primaryEmailUsedError, setPrimaryEmailUsedError] = useState(false);

  useEffect(() => {
    if (secondaryContact) {
      setFormData({
        firstName: _.get(secondaryContact, "firstName", "") || "",
        lastName: _.get(secondaryContact, "lastName", "") || "",
        email: _.get(secondaryContact, "email", "") || "",
        phoneNumber: _.get(secondaryContact, "phoneNumber", "") || "",
      });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const nextPage = "/onboarding/property-details";

    if (_.isEqual(formData, secondaryContact)) {
      handleSkip();
      return;
    }

    setIsLoading(true);
    try {
      const payload: any = {
        secondaryContact: formData,
        onboardingStep: nextPage,
      };
      const res = await api.portal.update(id, payload);

      if (!secondaryContactId) {
        payload.secondaryContactId = res.data.secondaryContactId;
      }

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("accCreationPage5", {
          secondary_contact_first_name: formData.firstName,
          secondary_contact_last_name: formData.lastName,
          secondary_contact_email: formData.email,
          secondary_contact_phone: formData.phoneNumber,
          page5_continue_clicked: true,
          page5_skip_clicked: false,
          page5_no_secondary_contact_clicked: false,
        }),
      });

      dispatch(setPortalProps(_.pick(payload, ['secondaryContact', 'secondaryContactId'])));
      dispatch(setMyProfile(_.pick(payload, ['onboardingStep'])));
      navigate(nextPage);
    } catch (error: any) {
      setIsLoading(false);
      const msg = "Failed to update contact. Please try again!";
      const errorDetails = _.get(error.response, "data.details");

      if (errorDetails && errorDetails === "PRIMARY_CONTACT_EMAIL_USED") {
        setPrimaryEmailUsedError(true)
        return
      }

      enqueueSnackbar(msg, {
        variant: "error",
        onClose: () => { },
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  const handleSkip = (noSecondaryContact = false) => {
    const nextPage = "/onboarding/property-details";
    const onboardingUpdate = { onboardingStep: nextPage };
    api.portal.update(id, onboardingUpdate).catch((err) => console.error(err));

    if (noSecondaryContact) {
      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("accCreationPage5", {
          secondary_contact_first_name: formData.firstName,
          secondary_contact_last_name: formData.lastName,
          secondary_contact_email: formData.email,
          secondary_contact_phone: formData.phoneNumber,
          page5_continue_clicked: false,
          page5_skip_clicked: false,
          page5_no_secondary_contact_clicked: true,
        }),
      });
    } else {
      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("accCreationPage5", {
          secondary_contact_first_name: formData.firstName,
          secondary_contact_last_name: formData.lastName,
          secondary_contact_email: formData.email,
          secondary_contact_phone: formData.phoneNumber,
          page5_continue_clicked: false,
          page5_skip_clicked: true,
          page5_no_secondary_contact_clicked: false,
        }),
      });
    }

    dispatch(setMyProfile(onboardingUpdate));
    navigate(nextPage);
  };

  useEffect(() => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phone = `+1${formData.phoneNumber}`;

    const debounce = setTimeout(() => {
      let errEmail = "",
        errPhone = "",
        withError = false;

      if (
        formData.firstName === "" ||
        formData.lastName === "" ||
        formData.email === "" ||
        formData.phoneNumber === ""
      ) {
        withError = true;
      }

      if (!emailPattern.test(formData.email) && formData.email !== "") {
        errEmail = "Invalid Email";
        withError = true;
      }

      if (formData.email === email) {
        errEmail = "You've entered the email we have on file. If you don't want to add another adult, select 'I don't have a secondary contact'.";
        withError = true;
      }

      if (formData.phoneNumber !== "" && !matchIsValidTel(phone)) {
        errPhone = "Invalid phone number";
        withError = true;
      }

      setErrors({
        email: errEmail,
        phoneNumber: errPhone,
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  return (
    <VerificationLayout
      currentStep={3}
      withBackButton={true}
      handleBack={() => navigate("/onboarding/phone")}
    >
      <HeaderContent
        handleBack={() => navigate("/onboarding/phone")}
        headerText="Add a secondary contact"
        headerVariant="h5"
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={true}
        currentStep={3}
      />
      <MiddleContainer>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="First Name"
            type="text"
            id="name-start-adornment"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="Last Name"
            type="text"
            id="name-start-adornment"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="Email"
            type="email"
            id="email-start-adornment"
            name="email"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
            error={errors.email !== "" ? true : false}
            helperText={errors.email}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <PhoneInput
            value={formData.phoneNumber}
            setValue={(v: any) => setFormData({ ...formData, phoneNumber: v })}
            error={errors.phoneNumber !== ""}
            setError={(e: any) => {
              setErrors({
                ...errors,
                phoneNumber: e ? "Invalid phone number" : "",
              });
            }}
            disabled={isLoading}
          />
        </FormControl>
        {primaryEmailUsedError && <Stack
          sx={{
            width: "100%",
            borderRadius: "8px",
            padding: "18px 20px",
            border: "1px solid #E8EBED",
            borderLeft: "3px solid #2660DF",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18px",
              letterSpacing: "0em",
              color: "#3D4D5C",
            }}
          >
            You've entered the email we have on file. If you don't want to add another adult, select 'I don't have a secondary contact'
          </Typography>
        </Stack>}
        <Box
          sx={{
            padding: "1.5rem",
            width: "295px",
            border: "1px solid #FFEDE0",
            background: "#FFF6F0",
            top: 0,
            left: "calc(100% + 3rem)",
            borderRadius: "8px",
          }}
          position={{
            xs: "static",
            lg: "absolute",
          }}
          marginX="auto"
        >
          <Stack gap={4}>
            <Typography
              variant="body2"
              textAlign="center"
              color={"primary"}
              sx={{
                fontWeight: `700 !important`,
              }}
            >
              Why are we asking for a secondary contact?
            </Typography>
            <Typography
              variant="body2"
              textAlign="center"
              color={"textPrimary"}
            >
              Adding another adult helps to manage your project. They will
              receive updates and be able to coordinate appointments. Clicking
              continue will send your secondary contact an invitation to your
              project tracker.
            </Typography>
          </Stack>
        </Box>
      </MiddleContainer>
      <BottomCtaContainer>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={() => handleSubmit()}
          disabled={isDisabled || isLoading}
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Continue
        </Button>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => handleSkip(true)}
        >
          I don’t have a secondary contact
        </Button>
        <Button variant="text" onClick={() => handleSkip()}>
          I’ll do this later
        </Button>
      </BottomCtaContainer>
    </VerificationLayout>
  );
};
