import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
  documents: [],
};

export const documentSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    setDocumentProps: (state, action: PayloadAction<any>) => {
      const updateObj = action.payload;

      _.each(updateObj, (v, k) => _.set(state, k, v));
      return state;
    },
  }
});

export const { setDocumentProps } = documentSlice.actions;

export default documentSlice.reducer;
