import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import _ from "lodash";
import parsePhoneNumber from 'libphonenumber-js'

import Stack from "@mui/material/Stack";
// import Divider from "@mui/material/Divider";

// import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import Checkbox from "@mui/material/Checkbox";

import { ContainerLayout } from "../../components/account/containerLayout";

import { useAppSelector, useAppDispatch } from "../../store/hook";
// import { setPortalProps } from "../../store/modules/portalSlice";
import { setMyProfileProps } from "../../store/modules/meSlice";
import * as api from "../../api";

const defaultValue = {
  // email: false,
  sms: false,
  // preferredTimeOfDay: {
  //   Morning: false,
  //   Afternoon: false,
  //   Evening: false,
  // }
};

export const AccountContactPreferences = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { phoneNumber } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const { notificationPrefs } = useAppSelector(
    (state) => state.me || ({} as any)
  );

  const [formData, setFormData] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  // const [isAllPrefTime, setIsAllPrefTime] = useState(false);
  const displayPhone = parsePhoneNumber(phoneNumber, 'US');
  const formattedPhoneNumber = (displayPhone ? displayPhone?.nationalNumber : phoneNumber).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

  useEffect(() => {
    const defaultData = {
      // email: notificationPrefs.email === "true",
      sms: notificationPrefs.sms,
      // preferredTimeOfDay: {
      //   Morning: false,
      //   Afternoon: false,
      //   Evening: false,
      // }
    };

    // if (notificationPrefs.preferredTimeOfDay) {
    //   const times = _.split(notificationPrefs.preferredTimeOfDay, ';');
    //   _.each(times, t => _.set(defaultData, `preferredTimeOfDay.${t}`, true));
    // }

    // if (
    //   defaultData.preferredTimeOfDay.Morning &&
    //   defaultData.preferredTimeOfDay.Afternoon &&
    //   defaultData.preferredTimeOfDay.Evening
    // ) {
    //   setIsAllPrefTime(true);
    // }

    setFormData(defaultData);
  }, []);

  const handlePrefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    const updatedFormData = { ...formData, [name]: checked };
    setFormData(updatedFormData);
    updateContactPreference(updatedFormData);
  };

  // const handleAllPrefTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsAllPrefTime(e.target.checked);
  //   const updatedFormData = {
  //     ...formData,
  //     preferredTimeOfDay: {
  //       Morning: e.target.checked,
  //       Afternoon: e.target.checked,
  //       Evening: e.target.checked,
  //     },
  //   };
  //   setFormData(updatedFormData);
  //   updateContactPreference(updatedFormData);
  // };

  // const handlePrefTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked, name } = e.target;
  //   const updatedFormData = {
  //     ...formData,
  //     preferredTimeOfDay: {
  //       ...formData.preferredTimeOfDay,
  //       [name]: checked,
  //     },
  //   };
  //   setFormData(updatedFormData);

  //   const allPrefTime =
  //     updatedFormData.preferredTimeOfDay.Morning &&
  //     updatedFormData.preferredTimeOfDay.Afternoon &&
  //     updatedFormData.preferredTimeOfDay.Evening;
  //   setIsAllPrefTime(allPrefTime);

  //   updateContactPreference(updatedFormData);
  // };

  const updateContactPreference = _.debounce(async (updatedFormData) => {
    setIsLoading(true);
    try {
      const _updatedFormData = _.cloneDeep(updatedFormData);
      // // const preferredTimeOfDay: string[] = [];
      // // _.each(updatedFormData.preferredTimeOfDay, (v, k) => {
      // //   if (v) preferredTimeOfDay.push(k)
      // // })

      // // _updatedFormData.preferredTimeOfDay = preferredTimeOfDay.join(';');
      // // await api.portal.update(id, { notificationPrefs: _updatedFormData });
      // dispatch(
      //   setPortalProps({
      //     notificationPrefs: {
      //       ...formData,
      //       email: formData.email.toString(),
      //       sms: formData.sms.toString(),
      //       preferredTimeOfDay: !_.isEmpty(preferredTimeOfDay) ? preferredTimeOfDay.join(';') : null
      //     },
      //   })
      // );
      api.me.updateMyProfile({ notificationPrefs: _updatedFormData })
      dispatch(
        setMyProfileProps({
          notificationPrefs: {
            sms: _updatedFormData.sms,
          },
        })
      );
      enqueueSnackbar("Contact preferences updated successfully", {
        variant: "default",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } catch (error) {
      enqueueSnackbar(
        "Failed to update contact preferences. Please try again!",
        {
          variant: "error",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, 500);

  return (
    <>
      <ContainerLayout headerText="Contact preferences" withMobileHeaderText>
        <Stack gap={4}>
          {/* <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  name="email"
                  checked={formData.email}
                  onChange={handlePrefChange}
                  disabled={isLoading}
                />
              }
              label="Email notifications"
            />
            <Typography variant="body2" color="textSecondary">
              Get email updates sent to <b>{email}</b>.
            </Typography>
          </FormControl> */}
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  name="sms"
                  checked={formData.sms}
                  onChange={handlePrefChange}
                  disabled={isLoading || notificationPrefs.smsDisabled}
                />
              }
              label="Text notifications"
            />
            {
              notificationPrefs.smsDisabled ? (
                <Typography variant="body2" color="textSecondary">
                  Text START to 512-489-3101 to receive SMS updates and appointment reminders to {formattedPhoneNumber}.
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Get SMS updates and appointment reminders texted to {formattedPhoneNumber}.
                </Typography>
              )
            }
          </FormControl>
        </Stack>
      </ContainerLayout>
      {/* <Divider sx={{ display: { xs: "none", sm: "block" } }} />
      <ContainerLayout headerText="Preferred Time of Day" withMobileHeaderText>
        <Box width="450px" maxWidth="100%">
          <FormControlLabel
            control={
              <Checkbox
                name="preferredTimeAll"
                checked={isAllPrefTime}
                onChange={handleAllPrefTimeChange}
                disabled={isLoading}
              />
            }
            label="Any time"
          />
          <Box paddingX={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="Morning"
                  checked={formData.preferredTimeOfDay.Morning}
                  onChange={handlePrefTimeChange}
                  disabled={isLoading}
                />
              }
              label="Morning (9am-12pm)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="Afternoon"
                  checked={formData.preferredTimeOfDay.Afternoon}
                  onChange={handlePrefTimeChange}
                  disabled={isLoading}
                />
              }
              label="Afternoon (12pm-5pm)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="Evening"
                  checked={formData.preferredTimeOfDay.Evening}
                  onChange={handlePrefTimeChange}
                  disabled={isLoading}
                />
              }
              label="Evening (5pm-9pm)"
            />
          </Box>
        </Box>
      </ContainerLayout> */}
    </>
  );
};
