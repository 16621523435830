import { useState, useEffect } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useNavigate,
  Outlet,
} from "react-router-dom";
import _ from "lodash";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { ErrorState } from "../components/shared/errorState";

import { useAppDispatch, useAppSelector } from "../store/hook";
import { setMyProfile, setMyProfileProps } from "../store/modules/meSlice";
import { setPortal } from "../store/modules/portalSlice";
import { isSessionAuthenticated } from "../services/auth";
import * as api from "../api";
import { EventWrapper } from "../components/shared/eventWrapper";
import useFaq from "../hooks/useFAQ";
import { setFaq } from "../store/modules/faqSlice";

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { portalId } = useParams();
  const { isImpersonating } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const { isBatteryOnly } = useAppSelector(
    (state) => state.portal || ([] as any)
  );

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { fetchFAQData } = useFaq();

  const redirect = (path: string) => {
    navigate(path);
    setIsLoading(false);
  };

  const populateFAQ = async () => {
    const groupedFaqData = await fetchFAQData();
    dispatch(setFaq(groupedFaqData));
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const isLoggedIn = await isSessionAuthenticated();
        setIsAuthenticated(isLoggedIn);

        if (isLoggedIn) {
          const excludeId: string[] = [];
          const promises = [api.me.myProfile()];

          if (portalId) {
            excludeId.push(portalId);
            promises.push(api.portal.get(portalId).catch(() => null));
          }

          let [{ data: myProfile }, portalRes] = await Promise.all(
            promises
          );

          dispatch(setMyProfile(myProfile));

          // Try lastVisitedPortal
          if (!portalRes && myProfile.lastVisitedPortal && !excludeId.includes(myProfile.lastVisitedPortal)) {
            excludeId.push(myProfile.lastVisitedPortal);
            portalRes = await api.portal.get(myProfile.lastVisitedPortal).catch(() => null);
          }

          // Try user's portals
          if (!portalRes && !_.isEmpty(myProfile.portals)) {
            const tryPortal = _.find(myProfile.portals, x => !excludeId.includes(x.id));
            if (tryPortal) {
              portalRes = await api.portal.get(tryPortal.id).catch(() => null);
            }
          }

          if (!portalRes) {
            redirect("/no-access");
            return;
          }

          const portal = portalRes.data;

          if (portalId && portalId !== portal.id) {
            window.history.pushState({}, "", location.pathname.replace(portalId, portal.id));
          }

          dispatch(setPortal(portal));
          setIsLoading(false);

          api.portal.getVelocity(portal.id).then(velocityRes => {
            dispatch(setPortal({ velocity: velocityRes.data }));
          })

          if (myProfile.onboardingStep !== "/onboarding/success") {
            if (!location.pathname.includes("onboarding"))
              navigate(myProfile.onboardingStep || `/onboarding/phone`);
            return;
          }

          /**
           * Redirect if no portal id or accessed onboarding page
           */
          if (!portalId || location.pathname.includes("onboarding")) {
            redirect(`/${portal.id}`);
            return;
          }

          if (!isImpersonating) {
            const profileUpdate: any = { lastVisitedPortal: portalId };
            if (!!portal.popup) {
              const type = portal.popup.type;
              dispatch(setMyProfileProps({ showPopup: type }));
              profileUpdate.viewedPopup = portal.popup.type;
            }
            api.me.updateMyProfile(profileUpdate).catch((err) => console.error(err));
            dispatch(setMyProfileProps({ lastVisitedPortal: portalId }));
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 404) {
          redirect("/no-access");
          return;
        }
        setIsError(true);
      }

      setIsLoading(false);
    };

    initialize();
  }, []);

  useEffect(() => {
    populateFAQ();
  }, [isBatteryOnly]);

  if (isError) {
    return (
      <Stack
        sx={{
          minHeight: "100dvh",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <ErrorState />
      </Stack>
    );
  }

  if (isLoading) {
    return (
      <Stack
        sx={{
          minHeight: "100dvh",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={60} />
      </Stack>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return (
    <EventWrapper>
      <Outlet />
    </EventWrapper>
  );
};
