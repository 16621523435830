import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import { useAppSelector } from "../../store/hook";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";

const overflowEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const projectStyle = {
  ...overflowEllipsis,
  padding: "0.75rem 1rem",
};

export const PortalsMenu = () => {
  const { portals } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const portal = useAppSelector((state) => state.portal || ({} as any));
  const [displayPortals, setDisplayPortals]: any = useState([]);
  const [activePortal, setActivePortal]: any = useState(null);
  const anchorEl: any = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handlePortalChange = (portalId: string) => {
    if (portal.id === portalId) return;

    const selectedPortal = _.find(displayPortals, (p) => p.id === portalId);
    if (!selectedPortal) return;

    let path = location.pathname;

    path = path.replace(portal.id, portalId);

    setActivePortal(selectedPortal);
    window.location.href = path;
  };

  useEffect(() => {
    let validPortals = _.cloneDeep(portals);
    validPortals = _.sortBy(validPortals, "created_at");

    setDisplayPortals(validPortals);

    const selectedPortal = _.find(portals, (p) => p.id === portal.id);
    if (!selectedPortal) {
      navigate("/no-access");
      return;
    }

    setActivePortal(selectedPortal as any);
  }, []);

  return displayPortals.length > 1 ? (
    <Stack ref={anchorEl} sx={{ position: "relative" }}>
      <Button
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={() => setOpen(true)}
        fullWidth
        sx={{
          height: "auto",
          fontWeight: 400,
          color: "#FFFFFF",
          backgroundColor: "#3D4D5C",
          padding: "10px 16px",
          gap: "4px",
          justifyContent: "space-between",
          maxWidth: "100$",
        }}
      >
        <Stack sx={{ maxWidth: "calc(100% - 28px)", textAlign: "left" }}>
          <Typography fontWeight={600} sx={overflowEllipsis}>{`Project ${_.findIndex(
            displayPortals,
            (p: any) => activePortal && p.id === activePortal.id
          ) + 1
            } - ${activePortal
              ? moment(activePortal.created_at).format("MMM YYYY")
              : ""
            }`}</Typography>
          <Typography variant="caption" sx={overflowEllipsis}>
            {activePortal?.address || activePortal?.id || " "}
          </Typography>
        </Stack>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl?.current}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          paper: {
            sx: {
              marginTop: "15px !important",
              width: anchorEl?.current?.clientWidth,
              backgroundColor: "#3D4D5C",
              "& ul": {
                padding: 0,
              },
            },
          },
        }}
      >
        {_.map(displayPortals, (portalData, index) => {
          const address = portalData?.address || portalData?.id;
          const isSelected = activePortal && portalData.id === activePortal.id;
          return (
            <MenuItem
              onClick={() => handlePortalChange(portalData.id)}
              sx={{
                display: "flex",
                gap: "4px",
                justifyContent: "space-between",
                padding: "12px 16px",
                backgroundColor: isSelected ? "#F98A3C33" : "",
                color: isSelected ? "#F98A3C" : "#FFFFFF",
                borderBottom: "1px solid #252F38",
              }}
              key={`portal${index}`}
            >
              <Stack sx={{ maxWidth: "calc(100% - 28px)" }}>
                <Typography fontWeight={600} sx={overflowEllipsis}>{`Project ${index + 1
                  } - ${moment(portalData.created_at).format(
                    "MMM YYYY"
                  )}`}</Typography>
                <Typography variant="caption" sx={overflowEllipsis}>
                  {address}
                </Typography>
              </Stack>
              <Stack width="24px">{isSelected && <CheckIcon />}</Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  ) : (
    <Box sx={projectStyle}>{activePortal?.address || activePortal?.id || " "}</Box>
  );
};
