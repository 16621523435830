import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../components/shared/phoneInput";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { MiddleContainer } from "../../components/signup/middleContainer";
import { BottomCtaContainer } from "../../components/signup/bottomCtaContainer";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";
import { setMyProfileProps, setMyProfile } from "../../store/modules/meSlice";

import * as api from "../../api";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

const defaultNotificationPrefs = {
  sms: false,
};

export const PhoneNumberOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    id,
    phoneNumber,
    secondaryContactId,
    secondaryContact,
  } = useAppSelector((state) => state.portal || ({} as any));
  const { customerId, notificationPrefs, emailVerified, phoneNumberVerified } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const isSecondaryContact = secondaryContactId === customerId;
  const onboardingStepCount = isSecondaryContact ? 6 : 2;
  const currentStep = phoneNumberVerified
    ? onboardingStepCount * 1
    : onboardingStepCount - 0.5;

  const [phoneValue, setPhoneValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(defaultNotificationPrefs);

  useEffect(() => {
    if (isSecondaryContact) {
      setPhoneValue(secondaryContact.phoneNumber || "");
    } else if (phoneNumber) {
      setPhoneValue(phoneNumber);
    }

    const defaultData = {
      sms: notificationPrefs.sms,
    };

    setFormData(defaultData);
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      const phone = `+1${phoneValue}`;
      let withError = false;
      let disable = true;

      if (phoneValue !== "" && !matchIsValidTel(phone)) {
        disable = true;
        withError = true;
      }

      if (matchIsValidTel(phone) && phoneValue !== "") {
        disable = false;
        withError = false;
      }

      setError(withError);
      setIsDisabled(disable);
    }, 300);

    return () => clearTimeout(debounce);
  }, [phoneValue]);

  const handlePrefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    const updatedFormData = { ...formData, [name]: checked };
    setFormData(updatedFormData);
    updateContactPreference(updatedFormData);
  };

  const handleSubmit = async () => {
    const currentPhone = isSecondaryContact
      ? secondaryContact.phoneNumber
      : phoneNumber;
    if (phoneValue === currentPhone && phoneNumberVerified) {
      handleSkip();
      return;
    }

    const nextPage = "/onboarding/phone/verify";

    setIsLoading(true);
    try {
      const payload: any = {
        phoneNumberVerified: false,
        onboardingStep: nextPage,
      };

      if (isSecondaryContact) {
        payload.secondaryContact = {
          ...secondaryContact,
          phoneNumber: phoneValue,
        };
      } else {
        payload.phoneNumber = phoneValue;
      }

      await api.portal.update(id, payload as any);
      await api.onboarding
        .sendPhoneVerification(id, phoneValue)
        .catch((err) => console.error(err));

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("phoneRequestCode", {
          phone: phoneValue,
        }),
      });

      dispatch(setPortalProps(_.pick(payload, ['secondaryContact'])));
      dispatch(setMyProfile(_.pick(payload, ['phoneNumberVerified', 'phoneNumber', 'onboardingStep'])));
      navigate(nextPage);
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const handleSkip = () => {
    const nextPage = isSecondaryContact
      ? "/onboarding/success"
      : "/onboarding/second-contact";

    const onboardingUpdate = { onboardingStep: nextPage };
    api.portal.update(id, onboardingUpdate).catch((err) => console.error(err));

    dispatch(setMyProfile(onboardingUpdate));
    navigate(nextPage);
  };

  const updateContactPreference = _.debounce((updatedFormData) => {
    const _updatedFormData = _.cloneDeep(updatedFormData);

    api.me
      .updateMyProfile({ notificationPrefs: _updatedFormData })
      .then(() => {
        dispatch(
          setMyProfileProps({
            notificationPrefs: {
              sms: _updatedFormData.sms,
            },
          })
        );
      })
      .catch((err) => console.error(err));
  }, 500);

  return (
    <VerificationLayout
      currentStep={currentStep}
      withBackButton={!emailVerified}
      handleBack={() => navigate("/onboarding/email")}
    >
      <HeaderContent
        handleBack={() => navigate("/onboarding/email")}
        headerText="Add your phone number"
        headerVariant="h5"
        subHeaderText="We’ll contact you regarding appointments and other details needed for your solar installation. "
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={!emailVerified}
        currentStep={currentStep}
      />
      <MiddleContainer>
        <FormControl variant="outlined" fullWidth>
          <PhoneInput
            value={phoneValue}
            setValue={setPhoneValue}
            error={error}
            setError={setError}
            disabled={isLoading}
          />
        </FormControl>
        <Box>
          <Checkbox
            name="sms"
            checked={formData.sms}
            onChange={handlePrefChange}
            sx={{ padding: 0, marginRight: "5px" }}
          />
          <Typography variant="body1" color="text.secondary" component="span">
            By checking this box, I agree to receive appointment reminder texts
            and other details related to my installation from Freedom Solar
            Power. Message frequency will vary. Message + data rates may apply.
            Reply STOP to unsubscribe or HELP for help. View terms/conditions
            and privacy policy <Link target="_blank" href="https://freedomsolarpower.com/sms-texting-terms-and-conditions">here</Link>.
          </Typography>
        </Box>
      </MiddleContainer>
      <BottomCtaContainer>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={() => handleSubmit()}
          disabled={isLoading || isDisabled}
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Continue
        </Button>
      </BottomCtaContainer>
    </VerificationLayout>
  );
};
